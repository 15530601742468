.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  min-height: 100vh;
  align-items: center;
  border-radius: 10px;
  flex-direction: column;
  justify-content: flex-start;
  font-family: Inter;
}
.home-header {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  justify-content: space-between;
}
.home-container01 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-image {
  width: 70px;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.home-text {
  margin-left: var(--dl-space-space-unit);
}
.home-nav {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.navigation-links-text{
  cursor: pointer;
}
.home-btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-button {
  color: var(--dl-color-gray-white);
  width: var(--dl-size-size-xlarge);
  transition: 0.3s;
  flex-shrink: 0;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: #0074F0;
}
.home-button:hover {
  cursor: pointer;
  transform: scale(1.02);
}
.home-text004:hover {
  transform: scale(1.02);
}
.home-burger-menu {
  display: none;
}
.home-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  display: none;
}
.home-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.home-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container02 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-image1 {
  height: 2rem;
}
.home-close-mobile-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-hero {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  min-height: 80vh;
  align-items: center;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-container03 {
  display: flex;
  flex-direction: column;
}
.home-text006 {
  width: 100%;
  font-size: 3rem;
  max-width: 450px;
}
.home-text010 {
  color: rgb(0, 116, 240);
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-video {
  width: 60%;
  height: auto;
  clip-path: inset(1px 1px);
}
.home-features {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
}
.home-text016 {
  margin-top: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-container04 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.home-feature-card {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
}
.home-icon10 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
}
.home-text019 {
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text022 {
  color: var(--dl-color-gray-700);
  text-align: center;
}
.home-feature-card1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
}
.home-icon12 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
}
.home-text025 {
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text028 {
  color: var(--dl-color-gray-700);
  text-align: center;
}
.home-feature-card2 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
}
.home-icon14 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
}
.home-text031 {
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text034 {
  color: var(--dl-color-gray-700);
  text-align: center;
}
.home-feature-card3 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
}
.home-icon16 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
}
.home-text037 {
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text040 {
  color: var(--dl-color-gray-700);
  text-align: center;
}
.home-pricing {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
}
.home-card {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-threeunits);
  background-color: var(--dl-color-gray-white);
}
.home-container05 {
  display: flex;
  min-width: 150px;
  align-items: center;
  flex-direction: column;
}
.home-text043 {
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 300;
  text-transform: uppercase;
}
.home-text044 {
  margin: var(--dl-space-space-halfunit);
  font-size: 4rem;
  font-style: normal;
  font-weight: 500;
}
.home-text045 {
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 300;
}
.home-text046 {
  width: 100%;
  margin-left: var(--dl-space-space-threeunits);
  margin-right: var(--dl-space-space-sixunits);
}
.home-button1 {
  color: var(--dl-color-gray-white);
  width: var(--dl-size-size-xlarge);
  text-align: center;
  transition: 0.3s;
  flex-shrink: 0;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-gray-black);
}
.home-button1:hover {
  cursor: pointer;
  transform: scale(1.02);
}
.home-text049 {
  text-align: center;
}
.home-text050:hover {
  transform: scale(1.02);
}
.home-card1 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-threeunits);
  background-color: var(--dl-color-gray-white);
}
.home-container06 {
  display: flex;
  min-width: 150px;
  align-items: center;
  flex-direction: column;
}
.home-text052 {
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 300;
  text-transform: uppercase;
}
.home-text053 {
  margin: var(--dl-space-space-halfunit);
  font-size: 4rem;
  font-style: normal;
  font-weight: 500;
}
.home-text056 {
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 300;
}
.home-text057 {
  width: 100%;
  margin-left: var(--dl-space-space-threeunits);
  margin-right: var(--dl-space-space-sixunits);
}
.home-button2 {
  color: var(--dl-color-gray-black);
  width: var(--dl-size-size-xlarge);
  flex-shrink: 0;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-gray-white);
}
.home-text061:hover {
  transform: scale(1.02);
}
.home-features1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
}
.home-text063 {
  font-size: 2rem;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-container07 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  margin-top: var(--dl-space-space-unit);
  grid-template-columns: 1fr 1fr 1fr;
}
.home-feature-card4 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  transition: 0.3s;
  align-items: center;
  padding-top: 32px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
}
.home-feature-card4:hover {
  border: none;
  cursor: pointer;
  transform: scale(1.02);
}
.home-icon18 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
}
.home-text064 {
  font-style: normal;
  margin-top: var(--dl-space-space-oneandhalfunits);
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text067 {
  color: var(--dl-color-gray-700);
  text-align: center;
}
.home-feature-card5 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  transition: 0.3s;
  align-items: center;
  padding-top: 32px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
}
.home-feature-card5:hover {
  border: none;
  cursor: pointer;
  transform: scale(1.02);
}
.home-icon20 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
}
.home-text070 {
  font-style: normal;
  margin-top: var(--dl-space-space-oneandhalfunits);
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text073 {
  color: var(--dl-color-gray-700);
  text-align: center;
}
.home-feature-card6 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  transition: 0.3s;
  align-items: center;
  padding-top: 32px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
}
.home-feature-card6:hover {
  border: none;
  cursor: pointer;
  transform: scale(1.02);
}
.home-icon22 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
}
.home-text076 {
  font-style: normal;
  margin-top: var(--dl-space-space-oneandhalfunits);
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text079 {
  color: var(--dl-color-gray-700);
  text-align: center;
}
.home-features2 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  flex-direction: column;
}
.home-text082 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-container08 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  grid-template-columns: 1fr;
}
.home-ul {
  height: auto;
  margin-bottom: 0px;
  list-style-type: decimal;
  list-style-position: outside;
}
.home-li {
  list-style-position: outside;
}
.home-container09 {
  flex: 0 0 auto;
  width: 70%;
  display: flex;
  margin-top: var(--dl-space-space-fourunits);
  align-items: flex-start;
  flex-direction: column;
}
.home-text090 {
  height: var(--dl-size-size-small);
}
.home-text095 {
  line-height: 2;
}
.home-container10 {
  flex: 0 0 auto;
  width: 70%;
  display: flex;
  margin-top: var(--dl-space-space-fourunits);
  align-items: flex-start;
  flex-direction: column;
}
.home-text096 {
  height: var(--dl-size-size-small);
}
.home-text101 {
  line-height: 2;
}
.home-container11 {
  flex: 0 0 auto;
  width: 70%;
  display: flex;
  margin-top: var(--dl-space-space-fourunits);
  align-items: flex-start;
  flex-direction: column;
}
.home-text104 {
  height: var(--dl-size-size-small);
}
.home-text108 {
  line-height: 2;
}
.home-text109 {
  height: var(--dl-size-size-small);
  margin-top: var(--dl-space-space-fourunits);
}
.home-container12 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container13 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  flex-direction: column;
}
.home-text113 {
  margin-top: var(--dl-space-space-unit);
}
.home-container14 {
  flex: 0 0 auto;
  width: 70%;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-threeunits);
  align-items: flex-start;
  flex-direction: column;
}
.home-text115 {
  margin-top: var(--dl-space-space-unit);
  line-height: 2;
}
.home-container15 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-threeunits);
  align-items: flex-start;
  flex-direction: column;
}
.home-text119 {
  margin-top: var(--dl-space-space-unit);
}
.home-container16 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-threeunits);
  align-items: flex-start;
  flex-direction: column;
}
.home-text125 {
  margin-top: var(--dl-space-space-unit);
}
.home-footer {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.home-container17 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-image2 {
  width: var(--dl-size-size-small);
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.home-text128 {
  margin-left: var(--dl-space-space-unit);
}

.email-input{
  border: 2px solid #0074F0;
  border-radius: 5px;
  outline: 0;
  width: 30%;
  padding: var(--dl-space-space-halfunit) var(--dl-space-space-halfunit);
}

@media(max-width: 991px) {
  .home-button {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-text004 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-icon {
    display: flex;
  }
  .home-hero {
    flex-direction: column;
  }
  .home-container03 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
  }
  .home-text006 {
    text-align: center;
  }
  .home-text007 {
    text-align: center;
  }
  .home-text016 {
    text-align: center;
  }
  .home-container04 {
    grid-template-columns: 1fr 1fr;
  }
  .home-text044 {
    margin-left: var(--dl-space-space-oneandhalfunits);
    margin-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-text046 {
    margin-left: var(--dl-space-space-threeunits);
    margin-right: var(--dl-space-space-threeunits);
  }
  .home-button1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-text050 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-text053 {
    margin-top: var(--dl-space-space-halfunit);
    margin-left: var(--dl-space-space-halfunit);
    margin-right: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .home-text054 {
    margin-top: var(--dl-space-space-halfunit);
    margin-left: var(--dl-space-space-halfunit);
    margin-right: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .home-text057 {
    margin-left: var(--dl-space-space-threeunits);
    margin-right: var(--dl-space-space-threeunits);
  }
  .home-button2 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-text061 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-text063 {
    text-align: center;
  }
  .home-container07 {
    grid-template-columns: 1fr 1fr;
  }
}
@media(max-width: 767px) {
  .home-header {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-nav {
    display: none;
  }
  .home-btn-group {
    display: none;
  }
  .home-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home-hero {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-features {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-text016 {
    text-align: center;
  }
  .home-pricing {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-card {
    width: 100%;
    flex-direction: column;
  }
  .home-text046 {
    margin-top: var(--dl-space-space-twounits);
    text-align: center;
    margin-left: var(--dl-space-space-twounits);
    margin-right: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-card1 {
    width: 100%;
    flex-direction: column;
  }
  .home-text057 {
    margin-top: var(--dl-space-space-twounits);
    text-align: center;
    margin-left: var(--dl-space-space-twounits);
    margin-right: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-features1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-text063 {
    text-align: center;
  }
  .home-features2 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-container08 {
    grid-template-columns: 1fr;
  }
  .home-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-text131 {
    text-align: center;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
}
@media(max-width: 479px) {
  .home-header {
    padding: var(--dl-space-space-unit);
  }
  .home-mobile-menu {
    padding: 16px;
  }
  .home-hero {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-container03 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-features {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-container04 {
    grid-template-columns: 1fr;
  }
  .home-pricing {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-text046 {
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .home-text057 {
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .home-features1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-container07 {
    grid-template-columns: 1fr;
  }
  .home-features2 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-footer {
    padding: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .home-text131 {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
}
